<template>
  <div class="s-points-index">
    <p v-if="fetching"><Loading /></p>
    <template v-else>
      <Notification class="s-m-3" ref="notification" @close="handleNotificationClosed" />
      <Hero title="紅利點數">
        <p class="s-font-semibold s-text-3xl s-py-5">{{ $currency(card.point, '') }}</p>
        <p class="s-text-xs">紅利點數將於 24 小時後發放</p>
      </Hero>
      <div class="s-bg-white s-mb-8">
        <header class="s-p-3 s-border-b s-border-gray-light">
          <PeriodSelect :value="months" @change="setMonths" />
        </header>
        <main class="s-px-3">
          <ul class="s-divide-y s-divide-y-gray-light" v-if="points.length">
            <Item :point="point" :key="i" v-for="(point, i) in points" />
          </ul>
          <div class="s-py-40 s-text-center" v-else>
            <img class="s-mb-3" src="@/assets/images/liff/empty-coin.svg">
            <p class="s-text-gray">沒有點數紀錄</p>
          </div>
        </main>
      </div>
      <Pagination :current="page" :last="lastPage" />
    </template>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Loading from '@/components/Page/Liff/Shared/Loading'
  import Item from '@/components/Page/Liff/MemberCard/Point/Item'
  import list from '@/mixins/liff/memberCard/list'
  import notification from '@/mixins/liff/memberCard/notification'

  export default {
    mixins: [list, notification],
    components: { Loading, Item },
    inject: ['$currency'],
    data: () => ({
      card: {},
      points: [],
    }),
    methods: {
      ...mapActions({
        getCard: 'liffMemberCard/getCard',
        getPoints: 'liffMemberCard/getPoints',
      }),
      async fetch () {
        const [card, { data: points, lastPage }] = await Promise.all([
          this.getCard(),
          this.getPoints({ page: this.page, months: this.months, count: this.count }),
        ])

        this.card = card
        this.points = points
        this.lastPage = lastPage
      },
    },
  }
</script>

<style scoped>
  .s-points-index {
    margin: -1rem -0.75rem 0;
  }
</style>
