<template>
  <li class="s-flex s-items-center s-py-5">
    <div class="s-mr-auto s-space-y-2">
      <p class="s-font-semibold s-text-gray-darker s-text-lg">{{ point.branch.name }}</p>
      <p class="s-text-gray-dark s-text-xs">{{ point.memo }}</p>
      <p class="s-text-gray s-text-xs">交易日期 {{ orderDate }}</p>
      <p class="s-text-gray s-text-xs">紅利點數效期 {{ expiredDate }}</p>
    </div>
    <div
      class="s-font-semibold s-text-xl s-flex-shrink-0"
      :class="pointClass"
    >
      {{ displayPoint }}
    </div>
  </li>
</template>

<script>
  import moment from 'moment'

  export default {
    props: {
      point: {
        type: Object,
        required: true
      },
    },
    inject: ['$currency'],
    computed: {
      orderDate () {
        return moment(this.point.order.order_at).format('YYYY-MM-DD')
      },
      expiredDate () {
        return moment(this.point.expired_at).format('YYYY-MM-DD')
      },
      pointIsNegative () {
        return this.point.point.includes('-')
      },
      displayPoint () {
        const point = this.point.point

        if (this.pointIsNegative) {
          return this.$currency(point.replace(/-/, ''), '- ')
        }

        return this.$currency(point, '+ ')
      },
      pointClass () {
        if (this.pointIsNegative) {
          return 's-text-gray-dark'
        }

        return 's-text-secondary'
      },
    },
  }
</script>
